import { logEvent } from 'firebase/analytics';
import { analytics } from '@/utils/firebase';
import mixpanel from "./mixpanel";

export const fireEvent = (eventName: string, eventParams?: object) => {
    logEvent(analytics, eventName, eventParams);
    mixpanel.track(eventName, eventParams);

    if (eventName === "searchcar_clicked") {
        // Twitter
        if (typeof window.twq === 'function') window.twq('event', 'tw-p0eup-p1szc', {});

        // Tiktok
        if (typeof window.ttq?.track === 'function') window.ttq.track('Search', {});

        // Facebook
        if (typeof window.fbq === 'function') window.fbq('track', 'Search');

        // Bing
        window.uetq = window.uetq || [];
        window.uetq.push('event', 'Search', {});
    }
};
