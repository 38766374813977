import { useEffect } from "react";
import { useRouter } from "next/router";
import axios from "axios";
import Cookies from "js-cookie";
import getURL from "@/utils/getUrl";
import { startUserSession } from "@/utils/tvlUserApis";
import { useUserId } from "@/hooks/useUserId";
import { returnRouteName } from "@/utils/returnRouteName";
import { getRoute } from "@/utils/getRoute";

type Props = {
  adData: AdData | undefined;
  type?: "top" | "bottom";
  countryCode: string;
};

const AdCard = ({ adData, countryCode, type }: Props) => {
  const router = useRouter();
  const { userId } = useUserId();
  let called = 0;

  const handleClick = () => {
    startUserSession({
      country_code: Cookies.get("region") || "US",
      fbclid: Array.isArray(router.query.fbclid) ? router.query.fbclid.pop() : router.query.fbclid,
      gclid: Array.isArray(router.query.gclid) ? router.query.gclid.pop() : router.query.gclid,
      msclkid: Array.isArray(router.query.msclkid) ? router.query.msclkid.pop() : router.query.msclkid,
      route: (returnRouteName(getRoute(router.query)) === "ads" ? "google" : returnRouteName(getRoute(router.query))) as VisitRoute,
      tag: "ad_click",
      user_id: userId,
      vertical: "car"
    });

    window.open(`https://kayak.com${adData.deepLink}`, "_blank");
  };

  useEffect(() => {
    if (called === 0 && adData) {
      called++;
      axios.get(`https://${getURL(countryCode)}${adData.impressionUrl}`)
        .then((res) => {
          // console.log(res.data, "Impression Registered");
        });
    }
  }, [adData]);

  return (
    <div className={`w-full fixed z-[1001] text-white flex items-center justify-center p-4 ${type === "bottom" ? "lg:bottom-[15vh] bottom-[15vh]" : "lg:top-[25vh] top-[10vh]"}`}>
      <div className="w-full rounded-xl shadow-md p-4 py-8 max-w-[350px] bg-cover bg-center bg-no-repeat search-btn-gradient">
        {adData ? (
          <>
            <div className="flex justify-center items-baseline py-4 w-full">
              <div className="flex justify-center items-center py-2 bg-white rounded-md">
                <img
                  src={`https://kayak.com${adData.logoUrl}`}
                  className="max-h-[30px] min-w-[80px] mx-2"
                  alt="Logo"
                />
              </div>
            </div>
            <div>
              <h2 className="font-[600] text-[24px] lg:text-[26px] max-h-[80px] text-ellipsis !text-white text-center">
                {adData.headline}
              </h2>
              <p className="font-normal text-sm mt-[8px] opacity-60 !text-white text-center">
                {adData.description}
              </p>
            </div>
            <button className="accent-primary w-full py-4 rounded-md font-[500] animate-zoom text-lg mt-8" onClick={handleClick}>
              {adData.bookingButtonText}
            </button>
          </>
        ) : (
          <>
            <img src="/ad-bg.png" width={300} height={300} alt="load image" className="" />
            <div>
              <h2 className="font-[600] text-[24px] lg:text-[26px] max-h-[80px] text-ellipsis !text-white text-center">
                Enjoy Amazing Deals!
              </h2>
              <p className="font-normal text-sm mt-[8px] opacity-60 !text-white text-center">
                Check out our fantastic offers and book online with confidence
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdCard;
